import {valueToFields} from "./interval.utility";

/**
 * Returns the formatted parameter value
 * @param key - the parameter key
 * @param value - the parameter value
 * @param p78 - responsible for module 1 state
 * @param p81 - responsible for module 2 state
 * @param p84 - responsible for module 3 state
 * @param p142 - pump signal
 * @param p186 - temperature unit
 * @returns {string|*}
 */

function valueFormatter(key, value, p78, p81, p84, p142, p186) {
    if (isToggleParameter(key)) {
        return value ? "On" : "Off";
    } else if (isOffDailyWeekly(key)) {
        if (parseInt(value) === 0) return "Off";
        else if (parseInt(value) === 1) return "Daily";
        else if (parseInt(value) === 2) return "Weekly";
    } else if (isOffOnSchedule(key)) {
        if (parseInt(value) === 0) return "Off";
        else if (parseInt(value) === 1) return "On";
        else if (parseInt(value) === 2) return "Schedule";
    } else if (isModuleType(key)) {
        if (parseInt(value) === 0) return "Disabled";
        else if (parseInt(value) === 1) return "Thermostat";
        else if (parseInt(value) === 2) return "Recycle";
        else if (parseInt(value) === 3) return "On/Off";
    } else if (isStorageSensor(key)) {
        if (parseInt(value) === 0) return "S1";
        else if (parseInt(value) === 1) return "S2";
        else if (parseInt(value) === 2) return "S3";
        else if (parseInt(value) === 3) return "S4";
    } else if (isHeatCool(key)) {
        return parseInt(value) === 0 ? "Heat" : "Cool";
    } else if (isLowHigh(key)) {
        return parseInt(value) === 1 ? "Low" : "High";
    } else if (isModuleRelay(key)) {
        if (parseInt(value) === 0) return "R1";
        else if (parseInt(value) === 1) return "R2";
        else if (parseInt(value) === 2) return "R3";
    } else if (isModuleSensor(key)) {
        if (parseInt(value) === 0) return "S1";
        else if (parseInt(value) === 1) return "S2";
        else if (parseInt(value) === 2) return "S3";
        else if (parseInt(value) === 3) return "S4";
    } else if (isModuleMonitor(key)) {
        if (parseInt(value) === 0) return "Off";
        else if (parseInt(value) === 1) return "On";
    } else if (isScheduleType(key)) {
        return scheduleFormatter(key, value, p78, p81, p84);
    } else if (isTemperatureType(key)) {
        return p186 ? value + '°F' : value + '°C';
    } else if (isSensorOffset(key)) {
        return p186 ? Math.fround(value / 10) + '°F' : Math.fround(value / 10) + '°C';
    } else if (isSensorType(key)) {
        return parseInt(value) === 0 ? 'PT1000' : 'NTC';
    } else if (key === 'p_71') { //if it is timezone parameter
        let text = "N/A";
        for (let i = 0; i < timezoneOptions.length; i++) {
            if (parseInt(timezoneOptions[i].value) === value) {
                text = timezoneOptions[i].text;
                break;
            }
        }
        return text;
    } else if (key === 'p_134') { //if it is pump type
        let val = parseInt(value);
        if (val === 0) return 'Off';
        else if (val === 1) return 'PWM';
        else return '0-10V';
    } else if (isPumpRelay(key)) {
        return parseInt(value) === 0 ? 'Standard' : 'V1';
    } else if (key === 'p_142') {
        return parseInt(value) === 0 ? 'Inverted' : 'Normal';
    } else if (key === 'p_186') { //temperature units
        return parseInt(value) === 0 ? 'Celsius' : 'Fahrenheit';
    } else if (key === 'p_138' || key === 'p_139' || key === 'p_140') {
        if (p142 == 0) {
            if (key === 'p_138') {
                return (100 - (10 - (value - 5))) / 10.0 + "V";
            } else if (key === 'p_139') {
                return (100 - (30 - (value - 20))) / 10.0 + "V";
            } else if (key === 'p_140') {
                return (100 - (100 - (value - 90))) / 10.0 + "V";
            }
        } else return (value / 10.0) + "V";
    } else if (key === 'p_135' || key === 'p_136' || key === 'p_137') {
        return value + "%"
    } else return value;
}

function scheduleFormatter(paramKey, schedule, p78, p81, p84) {
    let scheduleObj = JSON.parse(schedule);

    let formattedIntervals = [];
    for (let index in scheduleObj) {
        let interval = valueToFields(scheduleObj[index]);
        let value;

        if (isHeatPumpInterval(paramKey)) {
            value = interval[0] === 999 ? "Off" : "On";
        } else if (isModule1Interval(paramKey)) {
            if (p78 === 3) {
                value = interval[0] === 999 ? "Off" : "On";
            } else {
                value = interval[0] === 999 ? "Off" : interval[0] + '°C';
            }
        } else if (isModule2Interval(paramKey)) {
            if (p81 === 3) {
                value = interval[0] === 999 ? "Off" : "On";
            } else {
                value = interval[0] === 999 ? "Off" : interval[0] + '°C';
            }
        } else if (isModule3Interval(paramKey)) {
            if (p84 === 3) {
                value = interval[0] === 999 ? "Off" : "On";
            } else {
                value = interval[0] === 999 ? "Off" : interval[0] + '°C';
            }
        }

        let hours = interval[1];
        let minutes = interval[2];
        if (minutes < 10) minutes = "0" + minutes;
        if (hours < 10) hours = "0" + hours;

        formattedIntervals.push({
            value: value,
            startTime: hours + ":" + minutes
        });
    }

    let formattedSchedule = "";
    for (let i = 0; i < formattedIntervals.length; i++) {
        if (i === formattedIntervals.length - 1) {
            formattedSchedule += formattedIntervals[i].startTime + " - " + "24:00" + ": "
                + formattedIntervals[i].value + ""
        } else {
            formattedSchedule += formattedIntervals[i].startTime + " - " + formattedIntervals[i + 1].startTime + ": " + formattedIntervals[i].value + " <br> "
        }
    }

    return formattedSchedule;
}

function isTemperatureType(key) {
    return key === 'p_6' || key === 'p_7' || key === 'p_8' || key === 'p_9' || key === 'p_11' || key === 'p_12' || key === 'p_14' || key === 'p_15'
        || key === 'p_16' || key === 'p_18' || key === 'p_20' || key === 'p_21' || key === 'p_25' || key === 'p_26' || key === 'p_27' || key === 'p_28'
        || key === 'p_29' || key === 'p_32' || key === 'p_34' || key === 'p_35' || key === 'p_36' || key === 'p_37' || key === 'p_38' || key === 'p_39'
        || key === 'p_40' || key === 'p_48' || key === 'p_63' || key === 'p_64' || key === 'p_65' || key === 'p_66' || key === 'p_67' || key === 'p_87'
        || key === 'p_88' || key === 'p_89' || key === 'p_90' || key === 'p_91' || key === 'p_92' || key === 'p_93' || key === 'p_94' || key === 'p_95'
        || key === 'p_96' || key === 'p_97' || key === 'p_98' || key === 'p_99' || key === 'p_100';
}

function isSensorOffset(key) {
    return key === 'p_159' || key === 'p_160' || key === 'p_161' || key === 'p_162';
}

function isModuleMonitor(key) {
    return key === 'p_181' || key === 'p_182' || key === 'p_183' || key === 'p_184' || key === 'p_185'
}

function isToggleParameter(key) {
    return key === 'p_3' || key === 'p_5' || key === 'p_10' || key === 'p_13' || key === 'p_17' || key === 'p_19' || key === 'p_25'
        || key === 'p_37' || key === 'p_62' || key === 'p_70' || key === 'p_72' || key === 'p_73' || key === 'p_74' || key === 'p_75'
        || key === 'p_89';
}

function isOffDailyWeekly(key) {
    return key === 'p_22' || key === 'p_23' || key === 'p_24';
}

function isOffOnSchedule(key) {
    return key === 'p_68' || key === 'p_101' || key === 'p_103' || key === 'p_104' || key === 'p_104' || key === 'p_105' || key === 'p_107'
        || key === 'p_108' || key === 'p_109' || key === 'p_111' || key === 'p_112';
}

function isModuleType(key) {
    return key === 'p_78' || key === 'p_81' || key === 'p_84';
}

function isStorageSensor(key) {
    return key === 'p_177' || key === 'p_178' || key === 'p_179';
}

function isHeatCool(key) {
    return key === 'p_69' || key === 'p_102' || key === 'p_106' || key === 'p_110';
}

function isLowHigh(key) {
    return key === 'p_30' || key === 'p_31';
}

function isModuleRelay(key) {
    return key === 'p_79' || key === 'p_82' || key === 'p_85';
}

function isModuleSensor(key) {
    return key === 'p_80' || key === 'p_83' || key === 'p_86';
}

function isReadOnly(key) {
    return key === 'p_79' || key === 'p_80' || key === 'p_82' || key === 'p_83' || key === 'p_85' || key === 'p_86';
}

function isReadOnlyV2(key) {
    return key === 'p_79' || key === 'p_82' || key === 'p_85';
}

function isScheduleType(key) {
    return isHeatPumpInterval(key) || isModule1Interval(key) || isModule2Interval(key) || isModule3Interval(key);
}

function isHeatPumpInterval(key) {
    let value = parseInt(key.split('p_')[1]);

    return value >= 41 && value <= 47
}

function isModule1Interval(key) {
    let value = parseInt(key.split('p_')[1]);

    return value >= 113 && value <= 119;
}

function isModule2Interval(key) {
    let value = parseInt(key.split('p_')[1]);

    return value >= 120 && value <= 126;
}

function isModule3Interval(key) {
    let value = parseInt(key.split('p_')[1]);

    return value >= 127 && value <= 133;
}

function isSensorType(key) {
    return key === 'p_155' || key === 'p_156' || key === 'p_157' || key === 'p_158';
}

function isPumpRelay(key) {
    return key === 'p_144' || key === 'p_145' || key === 'p_146';
}

/**
 * Returns operation parameter key depending on module number and its configuration
 * @returns {string}
 */
function getOperationParameterKey(moduleNumber, parameters) {
    let operationParamKey;

    if (moduleNumber == 1) {
        if (parameters.p_78.value == 1) {
            operationParamKey = 'p_101';
        } else if (parameters.p_78.value == 2) {
            operationParamKey = 'p_103';
        } else if (parameters.p_78.value == 3) {
            operationParamKey = 'p_104';
        } else if (parameters.p_78.value == 4) {
            operationParamKey = 'p_56';
        } else if (parameters.p_78.value == 5) {
            operationParamKey = 'p_187';
        } else if (parameters.p_78.value == 6) {
            operationParamKey = 'p_205';
        }
    } else if (moduleNumber == 2) {
        if (parameters.p_81.value == 1) {
            operationParamKey = 'p_105';
        } else if (parameters.p_81.value == 2) {
            operationParamKey = 'p_107';
        } else if (parameters.p_81.value == 3) {
            operationParamKey = 'p_108';
        } else if (parameters.p_81.value == 4) {
            operationParamKey = 'p_59';
        } else if (parameters.p_81.value == 5) {
            operationParamKey = 'p_193';
        } else if (parameters.p_81.value == 6) {
            operationParamKey = 'p_211';
        }
    } else if (moduleNumber == 3) {
        if (parameters.p_84.value == 1) {
            operationParamKey = 'p_109';
        } else if (parameters.p_84.value == 2) {
            operationParamKey = 'p_111';
        } else if (parameters.p_84.value == 3) {
            operationParamKey = 'p_112';
        } else if (parameters.p_84.value == 4) {
            operationParamKey = 'p_147';
        } else if (parameters.p_84.value == 5) {
            operationParamKey = 'p_199';
        } else if (parameters.p_84.value == 6) {
            operationParamKey = 'p_217';
        }
    } else {
        if (parameters.p_1.value == 6 || parameters.p_1.value == 7) { //heatpump module variant 6
            operationParamKey = 'p_68';
        }
    }

    return operationParamKey;
}

/**
 * Get the setpoint value of the current interval when schedule is on.
 */
function getSetpointValue(paramKey, parameters) {
    let date = new Date();
    let day = date.getDay();
    let timezone = parseInt(parameters.p_71.value);
    let timezoneOffset = parseInt(timezoneOptions[timezone].text.split('UTC')[1]);
    let nowInMinutes = (date.getUTCHours() + timezoneOffset) * 60 + date.getMinutes();

    let key = 'p_';
    if ((paramKey === 'p_35' && parseInt(parameters.p_101.value) === 2) || (paramKey === 'p_39' && parseInt(parameters.p_103.value) === 2)) {
        if (day === 0) {
            key += 119;
        } else {
            key += 112 + day;
        }
    } else if ((paramKey === 'p_87' && parseInt(parameters.p_105.value) === 2) || (paramKey === 'p_91' && parseInt(parameters.p_107.value) === 2)) {
        if (day === 0) {
            key += 126;
        } else {
            key += 119 + day;
        }
    } else if ((paramKey === 'p_94' && parseInt(parameters.p_109.value) === 2) || (paramKey === 'p_98' && parseInt(parameters.p_111.value) === 2)) {
        if (day === 0) {
            key += 133;
        } else {
            key += 126 + day;
        }
    }

    let rawIntervals = JSON.parse(parameters[key].value);
    let length = Object.keys(rawIntervals).length;
    let currInterval = valueToFields(rawIntervals[1]); //intervals start at 1 not 0
    let currTotalInMinutes = currInterval[1] * 60 + currInterval[2];
    let setpoint = currInterval[0];

    for (let i = 2; i < length + 2; i++) {
        let nextInterval = valueToFields(rawIntervals[i]);
        let nextTotalInMinutes;

        if (i !== length + 1) { //if not last interval
            nextTotalInMinutes = nextInterval[1] * 60 + nextInterval[2];
        } else nextTotalInMinutes = 24 * 60;

        if (nowInMinutes > currTotalInMinutes && nowInMinutes <= nextTotalInMinutes) {
            setpoint = currInterval[0];
            break;
        } else {
            currInterval = nextInterval;
            currTotalInMinutes = nextTotalInMinutes;
        }
    }
    let unit = parameters.p_186 ? (parameters.p_186.value ? '°F' : '°C') : '°C';
    setpoint = setpoint === 999 ? "Off" : setpoint + unit;

    return setpoint;
}

let timezoneOptions = [
    {value: 0, text: 'UTC-12:00'},
    {value: 1, text: 'UTC-11:00'},
    {value: 2, text: 'UTC-10:00'},
    {value: 3, text: 'UTC-9:30'},
    {value: 4, text: 'UTC-9:00'},
    {value: 5, text: 'UTC-8:00'},
    {value: 6, text: 'UTC-7:00'},
    {value: 7, text: 'UTC-6:00'},
    {value: 8, text: 'UTC-5:00'},
    {value: 9, text: 'UTC-4:00'},
    {value: 10, text: 'UTC-3:30'},
    {value: 11, text: 'UTC-3:00'},
    {value: 12, text: 'UTC-2:00'},
    {value: 13, text: 'UTC-1:00'},
    {value: 14, text: 'UTC±0:00'},
    {value: 15, text: 'UTC+1:00'},
    {value: 16, text: 'UTC+2:00'},
    {value: 17, text: 'UTC+3:00'},
    {value: 18, text: 'UTC+3:30'},
    {value: 19, text: 'UTC+4:00'},
    {value: 20, text: 'UTC+4:30'},
    {value: 21, text: 'UTC+5:00'},
    {value: 22, text: 'UTC+5:30'},
    {value: 23, text: 'UTC+5:45'},
    {value: 24, text: 'UTC+6:00'},
    {value: 25, text: 'UTC+6:30'},
    {value: 26, text: 'UTC+7:00'},
    {value: 27, text: 'UTC+8:00'},
    {value: 28, text: 'UTC+8:45'},
    {value: 29, text: 'UTC+9:00'},
    {value: 30, text: 'UTC+9:30'},
    {value: 31, text: 'UTC+10:00'},
    {value: 32, text: 'UTC+10:30'},
    {value: 33, text: 'UTC+11:00'},
    {value: 34, text: 'UTC+12:00'},
    {value: 35, text: 'UTC+12:45'},
    {value: 36, text: 'UTC+13:00'},
    {value: 37, text: 'UTC+14:00'}
];

function getSliderDotValue(parameterKey, val, max, min, parameters) {
    let formattedValue;
    let oem = process.env.VUE_APP_OEM;

    //don't divide pump flow by 10 for pleion
    if (((parameterKey === 'p_49' || parameterKey === 'p_50' || parameterKey === 'p_51') && oem === 'things') || parameterKey === 'p_135' || parameterKey === 'p_136'
        || parameterKey === 'p_137' || parameterKey === 'p_138' || parameterKey === 'p_139' || parameterKey === 'p_140') {

        if (parameters.p_142 && parameters.p_142.value == 0 && (parameterKey === 'p_135' || parameterKey === 'p_136' || parameterKey === 'p_137'
            || parameterKey === 'p_138' || parameterKey === 'p_139' || parameterKey === 'p_140')) {

            formattedValue = 100 - val;
        } else {
            formattedValue = val;
        }

        if (parameterKey === 'p_49' || parameterKey === 'p_50' || parameterKey === 'p_51' || parameterKey === 'p_138' || parameterKey === 'p_139'
            || parameterKey === 'p_140') {
            formattedValue /= 10.0;
        }

        formattedValue = Math.round(formattedValue * 10) / 10;
    } else if (parameterKey === 'p_159' || parameterKey === 'p_160' || parameterKey === 'p_161' || parameterKey === 'p_162') {
        formattedValue = val / 10.0;
    } else {
        formattedValue = val;
    }

    return formattedValue;
}

function convertHrsMinstToMins(hm) {
    if(!hm) return 0;
    var a = hm.split(':'); // split it at the colons
    var minutes = (+a[0]) * 60 + (+a[1]);
    return minutes;
}

function convertMinsToHrsMins(parameterKey, parameters) {
    let minutes = parameters[parameterKey].value??0;
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
}

export {
    valueFormatter,
    isReadOnly,
    isReadOnlyV2,
    isScheduleType,
    isTemperatureType,
    isHeatPumpInterval,
    isModule1Interval,
    isModule2Interval,
    isModule3Interval,
    getOperationParameterKey,
    getSetpointValue,
    getSliderDotValue,
    timezoneOptions,
    convertMinsToHrsMins,
    convertHrsMinstToMins
};
