<template>
    <div class="mt-lg-3 mt-1 pt-3 pb-0 px-3">
        <b-container fluid style="padding: 0">
            <b-row v-if="templatesList.length > 0">
                <b-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="(template, index) in templatesList" :key="index">
                    <b-card no-body class="card-material" style="padding: 16px;">
                        <b-row>
                            <b-col>
                                <span style="font-size: 16px;">{{ template.name }}</span>
                                <p style="color: grey;" class="mb-0">{{ template.created_at }}</p>
                            </b-col>

                            <b-col cols="12" class="mt-4 pr-0">
                                <b-button class="template-button" @click="$root.$emit('show-template-info', template)" variant="primary">
                                    <i style="color: var(--primary);" class="fa fa-search-plus"/>
                                </b-button>
                                <b-button class="template-button ml-2" @click="showRenameModal(index)" variant="primary">
                                    <i style="color: var(--primary);" class="fa fa-edit"/>
                                </b-button>
                                <b-button class="template-button ml-2" variant="primary " @click="showDeleteModal(index)">
                                    <i style="color: var(--primary);" class="fa fa-trash-o"/>
                                </b-button>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                    <b-card no-body class="card-material" style="padding: 16px;">
                        <b-card-text>{{ $t('templates.noTemplates') }}</b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>

        <view-template-modal/>

        <b-modal ref="templateRenameModal" centered header-bg-variant="primary" @ok="renameTemplate" :ok-disabled="!state">
            <div slot="modal-header" class="w-100">
                <h2 class="text-center">{{ $t('templates.renameTemplate') }}</h2>
            </div>

            <b-form-group id="fieldset1" :label="$t('templates.enterTemplateName')" label-for="name-input" :invalid-feedback="invalidFeedback"
                          :valid-feedback="validFeedback" f :state="state">
                <b-form-input id="name-input" :state="state" v-model.trim="templateName" @keypress.enter.native="pressedEnter"/>
            </b-form-group>

            <div slot="modal-ok">{{ $t('general.confirm') }}</div>
        </b-modal>

        <b-modal title="Success" ref="renameSuccessModal" centered header-bg-variant="primary" ok-only>
            {{ $t('templates.renameSuccessful') }}
        </b-modal>

        <b-modal title="Error" ref="renameErrorModal" centered header-bg-variant="danger" ok-only>
            {{ $t('templates.templateRenameError') }}
        </b-modal>

        <b-modal :title="$t('templates.deleteTemplate')" ref="deleteTemplateModal" centered header-bg-variant="danger" @ok="deleteTemplate"
                 :cancel-title="$t('general.cancel')">
            {{ $t('templates.deleteConfirmationMessage') }}
        </b-modal>

        <b-modal title="Error" ref="deleteErrorModal" centered header-bg-variant="danger" ok-only>
            {{ $t('templates.templateDeleteError') }}
        </b-modal>
    </div>
</template>

<script>
    import ViewTemplateModal from '@/components/virtual-device/edit/device-templates/ViewTemplateModal';
    import Vue from 'vue';

    export default {
        name: "Templates",

        components: {
            ViewTemplateModal
        },

        async beforeRouteEnter(to, from, next) {
            let response = await Vue.prototype.$axios.get('/templates-list');

            next(vm => {
                vm.templatesList = response.data;
            });
        },

        data() {
            return {
                templatesList: [],
                templateName: '',
                templateToRenameIndex: '',
                templateToDeleteIndex: '',
                templateRenameError: '',
                templateDeleteError: ''
            }
        },

        computed: {
            state() {
                return this.templateName.length >= 4 && this.templateName.length <= 20
            },

            invalidFeedback() {
                if (this.templateName.length > 4 && this.templateName.length <= 20) {
                    return ''
                } else if (this.templateName.length > 20) {
                    return this.$t('templates.nameMoreThan20Chars');
                } else if (this.templateName.length > 0) {
                    return this.$t('templates.nameMoreThan4Chars');
                } else {
                    return this.$t('templates.nameEmpty');
                }
            },

            validFeedback() {
                return this.state === true ? this.$t('templates.ready') : '';
            }
        },

        methods: {
            showRenameModal(index) {
                this.templateToRenameIndex = index;
                this.templateName = this.templatesList[index].name;
                this.$refs.templateRenameModal.show();
            },

            renameTemplate() {
                this.$axios({
                    method: 'post',
                    url: '/rename-template',
                    data: {
                        id: this.templatesList[this.templateToRenameIndex].id,
                        name: this.templateName
                    },
                    timeout: 10000
                }).then((response) => {
                    for (let i = 0; i < this.templatesList.length; i++) {
                        if (this.templatesList[i].id === response.data.id) {
                            Vue.set(this.templatesList[i], 'name', response.data.name);
                            break;
                        }
                    }
                    this.$refs.renameSuccessModal.show()
                }).catch((err) => {
                    let error = err.response.data;
                    if (error.type) {
                        if (error.type === 'DemoAccountError') {
                            this.templateRenameError = error.body;
                        }
                    } else {
                        this.templateRenameError = this.$t('templates.templateRenameError');
                    }

                    this.$refs.renameErrorModal.show()
                });
            },

            pressedEnter() {
                if (this.state) {
                    this.$refs.templateRenameModal.hide();
                    this.renameTemplate();
                }
            },

            showDeleteModal(index) {
                this.templateToDeleteIndex = index;
                this.$refs.deleteTemplateModal.show();
            },

            deleteTemplate() {
                this.$axios.delete('/delete-template', {
                    data: {
                        id: this.templatesList[this.templateToDeleteIndex].id
                    }
                }).then((response) => {
                    for (let i = this.templatesList.length - 1; i >= 0; i -= 1) {
                        if (this.templatesList[i].id === response.data.id) {
                            this.templatesList.splice(i, 1);
                        }
                    }
                }).catch((err) => {
                    let error = err.response.data;
                    if (error.type) {
                        if (error.type === 'DemoAccountError') {
                            this.templateDeleteError = error.body;
                        }
                    } else {
                        this.templateDeleteError = this.$t('templates.templateDeleteError');
                    }

                    this.$refs.deleteErrorModal.show();
                });
            }
        }
    }
</script>

<style scoped>
    .template-button {
        background: white !important;
        border-color: var(--primary);
    }
</style>