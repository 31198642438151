/**
 * Return rgb depending on temperature
 * @param temp - given temperature
 * @returns {string} - rgb value
 */
function temp2Color(temp) {
    let red;
    let green = 160;
    let blue;

    blue = 255 - parseInt(temp);
    red = 150 + parseInt(temp);

    return "rgb(" + red + "," + green + "," + blue + ")";
}

/**
 * Decodes temperature, start hour and start minute from given value
 * @param val - a number which has information about temperature, start hour and start minute encoded into it
 * @returns {number[]} - an array which holds the decoded values of temperature, start hour and start minute
 */
function valueToFields(val) {
    let setpoint;
    if (val < 0) {
        setpoint = -val & 0xFFFF;
        setpoint = -setpoint;
    } else {
        setpoint = val & 0xFFFF;
    }
    val = Math.abs(val);
    let minute = (val >> 16) & 0xFF;
    let hour = (val >> 24) & 0xFF;

    return [setpoint, hour, minute];
}

/**
 * Encodes setpoint, start hour and start minuted into a number
 * @param setpoint - the temperature we want to encode, can also be an off value
 * @param startTime - the time we want to encode
 * @returns {number} - the encoded value
 */
function fieldsToValue(setpoint, startTime) {
    let hour = parseInt(startTime.split(":")[0]);
    let min = parseInt(startTime.split(":")[1]);

    let val = hour;
    val = (val << 8) | min;
    val = (val << 16) | Math.abs(setpoint);
    if (setpoint < 0) {
        val = 0 - val;
    }

    return val;
}

/**
 * Returns time as a properly formatted string in HH:mm format
 * @param hour - a number which represents the hour
 * @param minute - a number which represents the minute
 * @returns {string}
 */
function parseTime(hour, minute) {
    let h = hour < 10 ? '0' + hour : hour;
    let m = minute < 10 ? '0' + minute : minute;

    return h + ":" + m;
}

/**
 * Create watchers for intervals parameters depending on the schedule that you are in.
 * @param schedule - reference of the schedule vue component
 * @return {Array} - returns an array with unwatchers for created watchers
 */
function setSchedulesWatchers(schedule) {
    let schedulesUnwatch = [];
    let parameters = schedule.parameters;
    let moduleNumber = schedule.moduleNumber;

    if (moduleNumber === 0) {
        if (parameters.p_41 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_41', schedule.scheduleWatcher, {
                deep: true
            }));
        }
        if (parameters.p_42 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_42', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_43 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_43', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_44 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_44', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_45 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_45', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_46 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_46', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_47 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_47', schedule.scheduleWatcher, {
                deep: true
            }));
        }
    } else if (moduleNumber === 1) {
        if (parameters.p_113 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_113', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_114 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_114', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_115 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_115', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_116 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_116', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_117 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_117', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_118 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_118', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_119 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_119', schedule.scheduleWatcher, {
                deep: true
            }));
        }
    } else if (moduleNumber === 2) {
        if (parameters.p_120 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_120', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_121 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_121', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_122 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_122', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_123 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_123', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_124 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_124', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_125 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_125', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_126 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_126', schedule.scheduleWatcher, {
                deep: true
            }));
        }
    } else if (moduleNumber === 3) {
        if (parameters.p_127 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_127', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_128 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_128', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_129 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_129', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_130 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_130', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_131 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_131', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_132 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_132', schedule.scheduleWatcher, {
                deep: true
            }));
        }

        if (parameters.p_133 !== undefined) {
            schedulesUnwatch.push(schedule.$watch('parameters.p_133', schedule.scheduleWatcher, {
                deep: true
            }));
        }
    }

    return schedulesUnwatch;
}

export {temp2Color, valueToFields, fieldsToValue, parseTime, setSchedulesWatchers};
